import { Download } from '@cfa-icons/system';
import { useAppSelector } from 'app/store';
import { selectLoadUserStatus } from 'app/store/users/selectors';
import { EmployeeImageData } from 'app/types/ImageTypes';
import { createDownloads } from 'app/utils/download';
import { Icon, Typography } from 'cfa-react-components';

interface PictureDownloadButtonProps {
  userImageData: EmployeeImageData[];
  staffOnly: boolean;
  name: string;
}

export const PictureDownloadButton = (params: PictureDownloadButtonProps) => {
  const status = useAppSelector(selectLoadUserStatus);

  return (
    <>
      <Typography
        variant="body1"
        color="secondary"
        fontWeight="medium"
        align="right"
        disabled={status.state !== 'idle'} // Conditionally set disabled based on isDisabled variable
        gutterBottom
        style={{
          marginTop: '40px',
          marginLeft: '85px',
          marginRight: '85px',
          margin: '0 auto',
        }}
        onClick={
          status.state == 'idle'
            ? () =>
                createDownloads(
                  params.userImageData,
                  params.staffOnly,
                  params.name,
                )
            : () => {}
        }
      >
        Download
        <Icon
          icon={Download}
          style={{
            color: status.state == 'idle' ? 'rgb(31,78,110)' : 'grey',
            marginLeft: '10px',
            marginBottom: '6px',
          }}
        ></Icon>
      </Typography>
    </>
  );
};
